/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState } from 'react';
import QrReader from 'react-qr-reader';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { Button, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useData } from '../DataProvider';
import { getSelectedItemById, pickTextColorBasedOnBgColorAdvanced, replaceSpecialChars } from '../../util/util';

export default function QRCodeViewer({ done }) {
  const { jsonData, selectedItems, setSelectedItems, setSearchText, selectedSceneIndex, selectColor } = useData();

  const [devices, setDevices] = useState([]);
  const [deviceId, setDeviceId] = useState(null);
  const [isAndroid, setIsAndroid] = useState(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [invertColor, setInvertColor] = useState(selectColor);
  const { t } = useTranslation();

  const isPhoneLandScape = useMediaQuery({ maxWidth: 950, orientation: 'landscape' });

  const handleScan = (qrCodeFabricId) => {
    if (qrCodeFabricId) {
      const qrCodeLink = qrCodeFabricId;
      const url = new URL(qrCodeLink);
      let fabric = url.searchParams.get('f');
      if (!fabric) {
        done('Falscher QR Code gescannt');
        return;
      }

      fabric = replaceSpecialChars(fabric);

      const foundItem = Object.values(jsonData.fabrics)
        .map((fabricArray) => fabricArray.filter((fabricList) => fabricList.post_title === fabric))
        .filter((elem) => elem.length > 0)[0][0];
      if (foundItem) {
        setSearchText(fabric);

        const currentScene = jsonData.scenes[selectedSceneIndex];

        jsonData.scenes.map((scene) => {
          scene.scene.hotspots.map((hotspot) => {
            if (foundItem.product_subgroup.includes(hotspot.product_group) && hotspot.product_group !== '7318') {
              getSelectedItemById(selectedItems, hotspot.product_group).fabric = foundItem;
              setSelectedItems({ ...selectedItems });
            }
          });
        });

        if (Object.values(currentScene.fabrics).includes(foundItem.id)) {
          currentScene.scene.hotspots.map((hotspot) => {
            if (foundItem.product_subgroup.includes(hotspot.product_group)) {
              getSelectedItemById(selectedItems, hotspot.product_group).fabric = foundItem;
              setSelectedItems({ ...selectedItems });
            } else if (hotspot.product_group !== '7318') {
              // closeFabricViewDialog({ error: true });
            }
          });
          done();
        } else {
          done('Stoff nicht in Szene enthalten');
        }
      } else {
        done('Stoff nicht in Länder/Kundenset enthalten');
      }
    }
  };

  useEffect(() => {
    if (selectColor) {
      const darkOrWhite = pickTextColorBasedOnBgColorAdvanced(selectColor, '#FFFFFF', '#000000');
      setInvertColor(darkOrWhite);
    }
  }, [selectColor]);

  const handleError = (err) => {
    // eslint-disable-next-line no-undef, no-alert
    alert(`qr code reader error: ${err.message}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // force permission request
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        stream.getTracks().forEach((track) => {
          track.stop();
        });
        const deviceList = await navigator.mediaDevices
          .enumerateDevices()
          .then((d) =>
            d
              .filter((types) => types.kind === 'videoinput')
              .filter((cameras) => cameras.label.includes('back') || cameras.label.includes('Rück')),
          );

        const localStorageDeviceId = localStorage.getItem('cameradeviceId');

        if (localStorageDeviceId) setDeviceId(localStorageDeviceId);
        else if (deviceList.length > 0) {
          setDeviceId(deviceList[0].deviceId);
        }
        if (devices.length === 0) setDevices(deviceList);
      } catch (e) {
        // console.warn('no permissions given');
      }
    };

    const { userAgent } = navigator;

    const isAndroidAgent = !!userAgent.match(/android/i);
    setIsAndroid(isAndroidAgent);

    if (!deviceId) fetchData();
  }, [devices, deviceId]);

  return (
    <div>
      {deviceId && devices.length > 1 && (
        <>
          <Typography variant="h6">{t('qrCameraText')}</Typography>
          <Grid
            style={{ display: 'flex', justifyContent: 'center', marginBottom: 8 }}
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
          >
            {devices &&
              devices.map((device, index) => (
                <Grid style={{ textAlign: 'center' }} item xs={12 / devices.length}>
                  <Button
                    style={{
                      background: deviceId === device.deviceId ? selectColor : 'unset',
                      color: invertColor,
                      border: '1px solid #d8d8d8',
                    }}
                    disabled={!isVideoLoaded}
                    onClick={() => {
                      localStorage.setItem('cameradeviceId', device.deviceId);
                      if (deviceId !== device.deviceId) {
                        setIsVideoLoaded(false);
                      }
                      setDeviceId(device.deviceId);
                    }}
                  >
                    {`${t('cameraText')} ${index + 1}`}
                  </Button>
                </Grid>
              ))}
          </Grid>
        </>
      )}
      {deviceId && <div style={{ wordBreak: 'break-all' }}>{JSON.stringify(devices)}</div>}
      {deviceId && <div style={{ wordBreak: 'break-all' }}>{deviceId}</div>}

      {(isAndroid === false || deviceId) && (
        <QrReader
          facingMode={deviceId && devices.length > 1 ? 'environment' : undefined}
          key={deviceId}
          delay={300}
          onError={handleError}
          onScan={handleScan}
          onLoad={() => setIsVideoLoaded(true)}
          constraints={
            deviceId
              ? {
                  deviceId: {
                    exact: deviceId,
                  },
                }
              : undefined
          }
          style={{ width: isPhoneLandScape ? '50%' : '100%', margin: '0 auto', height: '90%' }}
        />
      )}
    </div>
  );
}

QRCodeViewer.propTypes = {
  done: PropTypes.func.isRequired,
};
