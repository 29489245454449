import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Grid } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
import { makeStyles } from '@material-ui/core/styles';
import SearchBar from 'material-ui-search-bar';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QRIcon from '../assets/others/qr_code_icon.svg';
import FabricPagination from './FabricDialog/FabricPagination';
import { useWindowSize } from '../util/util';
import { useData } from './DataProvider';

const useStyles = makeStyles(() => ({
  buttonStyles: {
    border: '1px solid #F4F2F3',
    '@media (max-width:900px)': {
      width: '100%',
    },
    height: 55,
  },
  headerStyles: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  maxWidth: {
    width: '100%',
  },
  searchBarStyles: {
    '@media (max-width:500px)': {
      marginTop: 20,
      width: '100%',
    },
    '@media (min-width:900px)': {
      width: '50vw',
    },
  },
}));

export default function FabricSearchDialog({ onClose, openQRView }) {
  const maxWidth = 'lg'; // sm, md, lg
  const isPhone = useMediaQuery({ maxWidth: 500 });
  const isPortraitMode = useMediaQuery({ query: '(orientation: portrait)' });
  const classes = useStyles();
  const { searchText, setSearchText } = useData();
  const { t } = useTranslation();
  const [searchBarValue, setSearchBarValue] = useState(searchText);
  const [shouldReset, setReset] = useState(false);
  const size = useWindowSize();
  const widthPerItem = 150;
  const heightPerItem = 200;
  const headerHeight = isPhone && isPortraitMode ? 200 : 100;
  const numberOfRows = Math.floor((size.height - headerHeight) / heightPerItem);
  const numberOfTodos = Math.floor(size.width / widthPerItem) * numberOfRows;

  const closeButton = {
    position: 'absolute',
    right: '1%',
    top: '1%',
  };

  function containsTextFilter(mapItem) {
    if (mapItem) {
      // return mapItem.product.title.indexOf(searchBarValue.replace(/ /g, '')) !== -1;
    }
    return true;
  }

  useEffect(() => {
    if (searchText !== '') {
      setSearchBarValue(searchText);
    }
  }, [searchText, setSearchText]);

  return (
    <Dialog fullScreen maxWidth={maxWidth} open onClose={onClose} aria-labelledby="max-width-dialog-title">
      <DialogTitle id="max-width-dialog-title" style={{ textTransform: 'uppercase' }}>
        {t('search_for_fabric_qr')}
      </DialogTitle>
      <DialogActions>
        <IconButton style={closeButton} aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogActions>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Button
              className={classes.buttonStyles}
              startIcon={<img src={QRIcon} alt="QR Code Bild" style={{ height: 35 }} />}
              onClick={() => openQRView()}
            >
              {t('scan_qr')}
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <SearchBar
              className={classes.searchBarStyles}
              value={searchBarValue}
              onChange={(newValue) => {
                setReset(true);
                setSearchBarValue(newValue);
                setSearchText(newValue);
              }}
              onRequestSearch={() => {}}
              placeholder={t('enter_article_number')}
              onCancelSearch={() => setSearchBarValue('')}
              autoFocus
            />
          </Grid>
        </Grid>
        <FabricPagination
          filter={containsTextFilter}
          resetCounter={shouldReset}
          setReset={setReset}
          todosPerPage={numberOfTodos}
          searchBarValue={searchBarValue}
        />
      </DialogContent>
    </Dialog>
  );
}

FabricSearchDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  openQRView: PropTypes.func.isRequired,
};
