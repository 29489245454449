import React, { useRef, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import '../../carousel.css';

import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { useMediaQuery } from 'react-responsive';
import { Button } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import LayeringComponent from '../LayeringComponent';
import FabricViewDialog from '../FabricDialog/FabricViewDialog';
import { useData } from '../DataProvider';
import WatchListDialog from '../WatchListDialog/WatchListDialog';

export default function CarouselComponent() {
  const {
    isFullScreen,
    selectedSceneIndex,
    setSelectedSceneIndex,
    selectedItems,
    jsonData,
    wallColor,
    selectColor,
    allScenes,
  } = useData();
  const screenHeight = window.innerHeight - 74;

  const imageRef = useRef();
  const isPhonePortrait = useMediaQuery({ maxWidth: 550, orientation: 'portrait' });
  const isPhoneLandScape = useMediaQuery({ minWidth: 551, maxWidth: 950, maxHeight: 880, orientation: 'landscape' });
  const isTabletPortrait = useMediaQuery({ minWidth: 766, minHeight: 1000, orientation: 'portrait' });

  const [showDialog, setShowDialog] = useState(false);
  let imageHeight = screenHeight > 700 ? 700 : screenHeight;

  const menuHeight = () => {
    if (allScenes.length > 25 && isPhonePortrait) return 70;
    if (allScenes.length > 10 && isPhonePortrait) return 53;
    return 36;
  };

  const infoButton = () => {
    if (allScenes.length > 25 && isPhonePortrait) return 25;
    if (allScenes.length > 10 && isPhonePortrait) return 19;
    return 10;
  };

  if (isTabletPortrait) {
    imageHeight = 680;
  }
  if (isPhonePortrait) {
    imageHeight = 360;
  }
  if (isPhoneLandScape) {
    imageHeight = window.innerHeight;
  }

  const largeIconStyles = {
    width: 35,
    height: 35,
  };

  const button = {
    backgroundColor: 'transparent',
    backgroundRepeat: 'no-repeat',
    border: 'none',
    overflow: 'hidden',
    outline: 'none',
    color: '#4b4b4b',
    fontSize: 36,
  };

  const arrowStyles = {
    position: 'absolute',
    zIndex: 2,
    bottom: -10,
    width: 30,
    height: 48,
    cursor: 'pointer',
    ...button,
  };

  const indicatorStyles = {
    background: '#fff',
    width: 8,
    height: 8,
    display: 'inline-block',
    margin: '0 8px',
  };

  const navStyles = {
    backgroundColor: '#e2e6da',
    opacity: 0.7,
    height: 36,
    position: 'absolute',
    bottom: 0,
    width: '100%',
  };

  const fullScreenStyles = {
    position: 'absolute',
    height: window.innerHeight,
  };

  const croppedStyles = {
    width: '100%',
    height: imageHeight,
    objectFit: 'cover',
  };

  const layeringStyle = {
    position: 'absolute',
    height: imageHeight,
    ...croppedStyles,
  };

  const renderImages = allScenes.map((sceneItem, index) => (
    <div key={sceneItem.scene_type_id}>
      <LayeringComponent
        imageRef={imageRef}
        index={index}
        sceneItem={sceneItem}
        isFullScreen={isFullScreen}
        fullScreenStyles={fullScreenStyles}
        layeringStyle={layeringStyle}
        wallColor={wallColor}
        selectedItems={selectedItems}
        jsonData={jsonData}
      />

      <Button
        className="pulse-button"
        onClick={() => setShowDialog(true)}
        style={{
          position: 'absolute',
          right: 5,
          zIndex: 1,
          bottom: `${infoButton()}%`,
          color: 'white',
          borderRadius: '100%',
          minWidth: 30,
          background: selectColor,
          height: '35px',
          width: '35px',
        }}
        startIcon={<InfoOutlinedIcon style={{ width: 35, height: 35 }} />}
      />
      <div style={{ position: 'relative' }}>
        <div style={{ ...navStyles, ...{ height: menuHeight() } }}>&nbsp;</div>
      </div>
    </div>
  ));

  return (
    <>
      <Carousel
        showThumbs={false}
        selectedItem={selectedSceneIndex}
        statusFormatter={() => {}}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <button
              type="button"
              onClick={() => {
                onClickHandler();
                setSelectedSceneIndex(selectedSceneIndex - 1);
              }}
              title={label}
              style={{ ...arrowStyles, left: 0 }}
            >
              <NavigateBeforeIcon style={largeIconStyles} />
            </button>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <button
              type="button"
              onClick={() => {
                onClickHandler();
                setSelectedSceneIndex(selectedSceneIndex + 1);
              }}
              title={label}
              style={{ ...arrowStyles, right: 30 }}
            >
              <NavigateNextIcon style={largeIconStyles} />
            </button>
          )
        }
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          if (isSelected) {
            return (
              <li
                style={{ ...indicatorStyles, background: selectColor }}
                aria-label={`Selected: ${label} ${index + 1}`}
                title={renderImages.text}
                key={index}
              />
            );
          }
          return (
            <li
              style={indicatorStyles}
              onClick={() => {
                onClickHandler();
                setSelectedSceneIndex(index);
              }}
              onKeyDown={onClickHandler}
              value={index}
              key={index}
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
              role="button"
              tabIndex={0}
              aria-label={`${label} ${index + 1}`}
            />
          );
        }}
      >
        {renderImages}
      </Carousel>
      {jsonData && showDialog && <WatchListDialog handleClose={() => setShowDialog(false)} />}

      <FabricViewDialog isSpecial />
    </>
  );
}
