import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import i18next from 'i18next';
import MainPage from './MainPage';
import { useData } from '../components/DataProvider';
import QRCodeDialog from '../components/QRCode/QRCodeDialog';
import api from '../api/api';
import LoadingSpinner from '../components/Helper/LoadingSpinner';

export default function App() {
  const {
    setJsonData,
    setCardsMap,
    setSelectedItems,
    setFilterItems,
    setUserData,
    setUserId,
    setSelectColor,
    setSelectedSceneIndex,
    setBanner,
    setPrivacyPolicy,
    setAllScenes,
    setSceneTypeId: setSceneGroupId,
    setCurrentScene,
    setSelectedSceneId,
    setSetId,
    isLoading,
    setWallColor,
  } = useData();
  const [loading, setLoading] = useState(false);

  const customerSet = new URLSearchParams(window.location.search).get('customerSet');
  const userId =
    new URLSearchParams(window.location.search).get('userId') ||
    new URLSearchParams(window.location.search).get('user_id') ||
    process.env.REACT_APP_DEFAULT_USER_ID;
  const fabricIdHotspot1 = new URLSearchParams(window.location.search).get('hs1');
  const fabricIdHotspot2 = new URLSearchParams(window.location.search).get('hs2');
  const fabricIdHotspot3 = new URLSearchParams(window.location.search).get('hs3');
  const sceneIndex = new URLSearchParams(window.location.search).get('scene') || 0;
  const sceneGroupId = new URLSearchParams(window.location.search).get('sceneGroupId'); // 7467
  const language = new URLSearchParams(window.location.search).get('lang');

  useEffect(() => {
    const initCurrentProductGroup = (data, scenes) => {
      const dataset = [];
      const selectedFabricsObject = [];
      let productGroupCounter = 0;

      Object.entries(data.fabrics).map((entry) => {
        if (entry[1].length !== 0) productGroupCounter += 1;
        return entry[1].map((fabricArray) => {
          const currentEntry = Object.entries(scenes[0].activeFabrics).find((elem) => +elem[1] === fabricArray.id);
          if (!selectedFabricsObject[productGroupCounter])
            selectedFabricsObject[productGroupCounter] = {
              fabric: fabricArray,
              subgroupId: entry[0],
              sortKey: currentEntry[0],
            };

          if (currentEntry[0] < selectedFabricsObject[productGroupCounter].sortKey)
            selectedFabricsObject[productGroupCounter] = {
              fabric: fabricArray,
              subgroupId: entry[0],
              sortKey: currentEntry[0],
            };
          return dataset.push({
            product: fabricArray,
            isFavorite: false,
            subgroupId: entry[0],
          });
        });
      });

      setCardsMap(dataset);

      if (sceneIndex && sceneIndex < data.scenes.length) {
        setSelectedSceneIndex(+sceneIndex);
      } else {
        setSelectedSceneIndex(0);
      }

      if (fabricIdHotspot1 || fabricIdHotspot2 || fabricIdHotspot3) {
        const numberOfHotspots = data.scenes[sceneIndex].scene.hotspots.filter((item) => item.product_group !== '7318')
          .length;

        const hotspot1 = data.scenes[sceneIndex].scene.hotspots.filter((hotspot) => hotspot.subfolder === 'HS1')[0]
          .product_group;
        const hotspot2 =
          numberOfHotspots >= 2
            ? data.scenes[sceneIndex].scene.hotspots.filter((hotspot) => hotspot.subfolder === 'HS2')[0].product_group
            : '';

        const hotspot3 =
          numberOfHotspots >= 3
            ? data.scenes[sceneIndex].scene.hotspots.filter((hotspot) => hotspot.subfolder === 'HS3')[0].product_group
            : '';

        const fabricHS1 = data.fabrics[hotspot1].filter((obj) => obj.post_title === fabricIdHotspot1);
        const fabricHS2 =
          hotspot2 !== '' ? data.fabrics[hotspot2].filter((obj) => obj.post_title === fabricIdHotspot2) : [];
        const fabricHS3 = hotspot3 ? data.fabrics[hotspot3].filter((obj) => obj.post_title === fabricIdHotspot3) : [];

        if (fabricHS1.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          Object.values(selectedFabricsObject).filter((entry) => entry.subgroupId === hotspot1)[0].fabric =
            fabricHS1[0];
        }
        if (fabricHS2.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          Object.values(selectedFabricsObject).filter((entry) => entry.subgroupId === hotspot2)[0].fabric =
            fabricHS2[0];
        }
        if (fabricHS3.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          Object.values(selectedFabricsObject).filter((entry) => entry.subgroupId === hotspot3)[0].fabric =
            fabricHS3[0];
        }
      }

      setSelectedItems({ ...selectedFabricsObject.filter((elem) => elem !== null) });
    };

    async function fetchData(userSetId) {
      setLoading(true);
      setSetId(userSetId);
      try {
        const response = await api.fetchData(customerSet || userSetId, language);
        if (response.status === 200) {
          const { data } = response.data;
          setJsonData(data);
          localStorage.setItem('sattlerData', JSON.stringify(data));
          return data;
        }
      } catch (e) {
        const sattlerData = JSON.parse(localStorage.getItem('sattlerData'));
        setJsonData(sattlerData);
        return sattlerData;
      }

      return null;
    }

    async function loadUserData(id) {
      setSelectColor('#EFC743');
      if (id) {
        let userDataObj = null;
        try {
          userDataObj = await api.getUserData(id);
          localStorage.setItem('sattlerUserData', JSON.stringify(userDataObj));
        } catch (e) {
          userDataObj = JSON.parse(localStorage.getItem('sattlerUserData'));
        }
        const { userData } = userDataObj.data.data;
        if (userDataObj.status === 200) {
          setUserData(userDataObj);
          setUserId(id);
          const highlightColor =
            userData.highlightcolor && userData.highlightcolor !== '' ? userData.highlightcolor : '#EFC743';
          const col = highlightColor !== 'undefined' ? highlightColor : '#EFC743';
          setSelectColor(col);
          setBanner(userData.show_embedding_banner === 'true');
          setPrivacyPolicy(userData.show_privacy_policy);
          return userData;
        }
      }
      return null;
    }

    async function initData() {
      const returnUser = await loadUserData(userId);

      const langParam = new URLSearchParams(window.location.search).get('lang');

      if (returnUser && returnUser.userlng && !langParam) {
        i18next.changeLanguage(returnUser.userlng);
      }

      const currentSetId = returnUser && returnUser.setId && returnUser.setId !== '' ? returnUser.setId : '7468';
      const jsonData = await fetchData(currentSetId);

      let { scenes } = jsonData;
      if (sceneGroupId) {
        scenes = jsonData.scenes.filter((scene) => scene.scene_type_id === +sceneGroupId);
      }

      scenes.sort((a, b) => parseFloat(a.product_group) - parseFloat(b.product_group));

      if (returnUser && returnUser.customer_scenes && returnUser.customer_scenes !== '') {
        const sceneIds = returnUser.customer_scenes.split(',').map((sceneId) => +sceneId);
        const selectedScenes = [];

        sceneIds.forEach((sceneId) => {
          const scene = scenes.find((el) => el.scene.id === sceneId);
          if (scene) {
            selectedScenes.push(scene);
          }
        });
        scenes = selectedScenes;
        setAllScenes(scenes);
      } else {
        setAllScenes(scenes);
      }

      setCurrentScene(scenes[0]);
      setSelectedSceneId(scenes[0].scene.id);

      initCurrentProductGroup(jsonData, scenes);

      setLoading(false);
    }

    initData();
  }, [
    setCardsMap,
    setJsonData,
    setSelectedItems,
    setFilterItems,
    customerSet,
    sceneIndex,
    fabricIdHotspot1,
    fabricIdHotspot2,
    setSelectedSceneIndex,
    sceneGroupId,
    setAllScenes,
    setSceneGroupId,
    setSelectColor,
    setUserData,
    setUserId,
    setBanner,
    setPrivacyPolicy,
    userId,
    setCurrentScene,
    setSelectedSceneId,
    setSetId,
    fabricIdHotspot3,
    setWallColor,
    language,
  ]);

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      {!loading && (
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={MainPage} />
            <Route exact path="/qrcode" component={QRCodeDialog} />
          </Switch>
        </BrowserRouter>
      )}
    </div>
  );
}
