/* eslint-disable consistent-return */
import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useData } from '../DataProvider';
import WatchListCardTypeA from './WatchListCardTypeA';
import api from '../../api/api';
import { getProductGroupById, getSelectedItemById } from '../../util/util';

export default function WatchListDialog({ handleClose }) {
  const maxWidth = 'md';
  const { watchMap, selectedItems, userId, setId, jsonData, selectedSceneIndex, allScenes } = useData();
  const { t } = useTranslation();

  const text = t('fabric_detail');

  const closeButton = {
    position: 'absolute',
    right: '1%',
    top: '1%',
  };

  const downloadPDF = async () => {
    const pdfParams = {
      isWatchList: true,
      userId,
      fabricIds: Array.from(watchMap).map(([, value]) => value.product.id),
      setId,
    };

    const pdf = await api.generatePDF(pdfParams);
    const url = URL.createObjectURL(pdf.data);
    window.open(url);
  };

  return (
    <Dialog fullScreen maxWidth={maxWidth} open onClose={handleClose} aria-labelledby="max-width-dialog-title">
      <DialogTitle id="max-width-dialog-title">
        {text}
        <IconButton aria-label="close" style={closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {allScenes[selectedSceneIndex].scene.hotspots.map((hotspot) => {
          const productGroup = getProductGroupById(allScenes[selectedSceneIndex].product_group, jsonData);

          if (hotspot.product_group !== '7318')
            return (
              <WatchListCardTypeA
                cardItem={getSelectedItemById(selectedItems, hotspot.product_group).fabric}
                isGardenFurniture={productGroup.isGardenFurniture === 'true'}
              />
            );
          return '';
        })}
        <Button style={{ textTransform: 'capitalize' }} type="button" onClick={downloadPDF}>
          PDF-Download
        </Button>
      </DialogContent>
    </Dialog>
  );
}

WatchListDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
};
