import React, { useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import PWAPrompt from 'react-ios-pwa-prompt';
import NavigationBar from '../components/NavigationBar';
import CarouselComponent from '../components/Carousel/CarouselComponent';
import { useData } from '../components/DataProvider';
import logo from '../assets/logo/Sattler-blau.png';

export default function MainPage() {
  const { setLoading, isLoading, userData } = useData();

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, [setLoading]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '500px',
          }}
        >
          <img
            alt="Logo"
            width="100"
            src={
              userData.data && userData.data.data.userData.user_logo
                ? `${process.env.REACT_APP_BASE_URL + process.env.REACT_APP_UPLOADS_SCENES_FOLDER}/company_logo/${
                    userData.data.data.userData.user_logo
                  }`
                : logo
            }
          />
          <br />
          <br />
          <br />
          <br />
          <CircularProgress size={60} />
        </div>
      ) : (
        <div style={isLoading ? { display: 'none' } : null}>
          <NavigationBar />
          <PWAPrompt promptOnVisit={1} timesToShow={3} copyClosePrompt="Close" permanentlyHideOnDismiss={false} />

          <CarouselComponent />
        </div>
      )}
    </>
  );
}
