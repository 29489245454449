import * as React from 'react';
import { useEffect, useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as PropTypes from 'prop-types';
import useMediaQuery from 'react-responsive';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { t } from 'i18next';
import { closeMiniView, getTriggerOpenDialog } from '../Subjects/subjects';

const FabricViewDialog = ({ left, top, isSpecial }) => {
  const [open, setOpen] = React.useState(false);
  const [isHovering, setHovering] = React.useState(false);
  const [backgroundColor, setBackgroundColor] = React.useState('transparent');
  const [, setTabIndex] = React.useState(0);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const maxWidth = isTabletOrMobile ? 'sm' : 'md'; // sm, md, lg
  const childRef = useRef();

  const handleClickOpen = () => {
    setOpen(true);
    closeMiniView();
  };

  const handleClose = () => {
    setOpen(false);
  };

  getTriggerOpenDialog().subscribe((index) => {
    if (isSpecial) {
      setTabIndex(index);
      handleClickOpen();
    }
  });

  function showButtonLabel() {
    return (
      <div style={{ fontSize: ' max(0.7vw, 0.7rem)' }}>
        <b>Stoff auswählen</b>
        <br />
        <b>Dachstoff</b> - 314 344
      </div>
    );
  }

  const buttonLabelStyle = {
    position: 'absolute',
    left,
    top,
    background: backgroundColor,
    width: 'min(20vw, 200px)',
    borderTopRightRadius: '30px',
    borderBottomRightRadius: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'left',
    paddingLeft: '8px',
    zIndex: '1',
  };

  const closeButton = {
    position: 'absolute',
    right: '1%',
    top: '1%',
  };

  useEffect(() => {
    if (isHovering) {
      setBackgroundColor('#EFC743 0% 0% no-repeat padding-box');
    } else {
      setBackgroundColor('transparent');
    }
    if (childRef.current !== undefined) {
      const calcDiff = left - 200;
      childRef.current.style.left = calcDiff;
    }
  }, [isHovering, left]);

  // eslint-disable-next-line react/no-unstable-nested-components
  function HotspotButton() {
    return (
      <div
        ref={childRef}
        id="hotspot_button_1"
        className="hotspot "
        style={buttonLabelStyle}
        onMouseLeave={() => setHovering(false)}
      >
        <div>{isHovering ? showButtonLabel() : null}</div>
        <div className="button-wrap">
          <button
            type="button"
            className="pulse-button"
            onClick={handleClickOpen}
            onMouseEnter={() => setHovering(true)}
          >
            +
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      {!isSpecial ? <HotspotButton /> : null}
      <Dialog maxWidth={maxWidth} open={open} onClose={handleClose} aria-labelledby="max-width-dialog-title">
        <DialogTitle id="max-width-dialog-title">{t('select_fabric')}</DialogTitle>
        <DialogContent />
        <DialogActions>
          <IconButton aria-label="close" style={closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

FabricViewDialog.propTypes = {
  left: PropTypes.number,
  top: PropTypes.number,
  isSpecial: PropTypes.bool,
};

FabricViewDialog.defaultProps = {
  isSpecial: false,
  left: 0,
  top: 0,
};

export default FabricViewDialog;
